import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import getAssetLink from '../../utils/getAssetLink';
import Image from '../Image';
import VideoModal from '../VideoModal';

const Main = styled.div`
  position: relative;
  margin-bottom: 1em;
  cursor: pointer;
  background-color: whitesmoke;
  border-radius: 1em;
  overflow: hidden;

  .backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(36, 44, 52, 0.4);
  }

  .playButton {
    position: absolute;
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    z-index: 2;
    overflow: hidden;
  }

  .playButtonContentWrp {
    width: 66px;
    height: 66px;
    margin: -1px;
  }
`;

const playButton = {
  handle: 'SLQOzrORluusj8VkO7ag',
  width: 384,
  height: 382
};

class VideoThumbnail extends React.PureComponent {
  state = {
    showModal: false
  };

  triggerVideo = () => {
    this.setState({ showModal: true });
  };

  onRequestClose = () => {
    this.setState({ showModal: false });
    this.forceUpdate();
  };

  render() {
    const { poster, video, maxWidth = 800, ...props } = this.props;
    const { showModal } = this.state;

    return (
      <div>
        <Main {...props} onClick={this.triggerVideo}>
          <Image image={poster} maxWidth={maxWidth} />
          <div className="backdrop" />
          <div className="playButton">
            <div className="playButtonContentWrp">
              <Image image={playButton} maxWidth={64} />
            </div>
          </div>
        </Main>

        <VideoModal isOpen={showModal} onRequestClose={this.onRequestClose}>
          <video
            className="videoPlayer"
            controls
            preload="meta"
            autoPlay
            id="video-auto-play-modal"
            src={getAssetLink({ item: video })}
            type="video/mp4"
            style={{ width: '100%' }}
          >
            <track kind="captions" />
          </video>
        </VideoModal>
      </div>
    );
  }
}

VideoThumbnail.propTypes = {
  video: PropTypes.shape({}),
  poster: PropTypes.shape({}),
  maxWidth: PropTypes.number
};

export default VideoThumbnail;

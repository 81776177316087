import React from 'react';
import PropTypes from 'prop-types';

import cl from 'classnames';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { OnlineHeaderStep } from 'shared/OnlineHeaderStep';

import { Container, Section as SectonOrg } from '../../shared/Blocks';
import Image from '../../shared/Image';
import MainLayout from '../../shared/MainLayout';
import {
  BigSection as BigSectionOrg,
  ImagePanel,
  Panel
} from '../../shared/Marketing/Shared';
import MySEO from '../../shared/MySEO';
import VideoThumbnail from '../../shared/VideoThumbnail';
import HeaderSchedulePage from '../../shared/Header/HeaderSchedulePage';

const BigSection = styled(BigSectionOrg)`
  margin-top: 2em;
  margin-bottom: 2em;
`;

const Section = styled(SectonOrg)`
  background-color: #fbfbfb;

  @media screen and (min-width: 1024px) {
    min-height: 700px;
    display: flex;
    justify-content: stretch;
  }
`;

const VideoContentWrp = styled.div`
  display: none;

  .contentTitle {
    color: #242c34;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .contentBody {
    color: #2d3742;
    font-size: 18px;
  }

  &.active {
    display: block;
  }
`;

const BulletTitle = styled.div`
  font-size: 24px;
  margin-bottom: 1em;
  line-height: 1.2;
  font-weight: bold;
  color: #242c34;
  b {
    color: #d14242;
  }

  .bullet {
    font-size: 1.4em;
    font-weight: 600;
    flex: 50px 0 0;
    width: 50px;
    margin-top: -0.3em;
    margin-bottom: 0.4em;
    line-height: 1;
  }
  .content {
    font-size: 1em;
    font-weight: 600;
  }

  @media screen and (min-width: 1024px) {
    /* font-size: 32px; */
    position: relative;
    display: flex;

    .bullet {
      width: 80px;
      text-align: right;
      position: absolute;
      padding-right: 24px;
    }
    .content {
      padding-left: 80px;
    }
  }
`;

const img15g1 = {
  handle: '9giWqtBmRKyL1GHA6SXJ',
  width: 2028,
  height: 1952
};
const poster61 = {
  handle: 'HDxTK11WQoiugdcFgd8Y',
  width: 3456,
  height: 1944
};
const v1 = {
  handle: 'rw6qrhpKSMqRoclXoVXJ'
};

const Main = styled.main`
  .panelImage.Step1 {
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }
  .vh100 {
    height: calc(100vh - 152px);
    min-height: auto;
    overflow: hidden;
    padding-top: 0;
    @media screen and (max-width: 1023px) {
      overflow-y: auto;
      padding-top: 0;
    }
    b {
      color: #d14242;
    }
  }
  .vh100 section {
    margin: 0;
  }
  .vh100.noFooter {
    height: calc(100vh - 72px);
    overflow: hidden;
  }
  .FixedFooter {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px -3px 30px rgba(163, 163, 164, 0.2);
    position: fixed;
    bottom: 0;
    padding: 0 20px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    .wrapper-footer {
      max-width: 1140px;
      width: 1140px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
    }
  }
  /* .FixedFooter::before {
    content: '';
    position: fixed;
    width: 100%;
    height: 80px;
    left: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0px -3px 30px rgba(163, 163, 164, 0.2);
    z-index: -1;
  } */
  .btnBack,
  .btnNext {
    box-sizing: border-box;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: 160px;
    cursor: pointer;
  }
  .btnBack {
    border: 2px solid #ebecf0;
    background: #fff;
    color: #2d3742;
  }
  .btnNext {
    background: #d14242;
    color: #fff;
  }
  .TutorialVideo h6 {
    color: #242c34;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 16px;
  }
  .TutorialVideo {
    margin-top: 40px;
  }
  .RespHeader {
    position: relative;
    padding-left: 56px;
    margin-bottom: 32px;
    display: none;
    @media screen and (max-width: 1024px) {
      display: block;
    }
    .cirle {
      width: 42px;
      height: 42px;
      position: absolute;
      left: 0;
      .label {
        font-weight: bold;
        font-size: 12px;
        position: absolute;
        line-height: 38px;
        display: block;
        width: 100%;
        text-align: center;
        letter-spacing: 1.5px;
        border: 2px solid #a5adba;
        border-radius: 50%;
        b {
          color: #d14242;
        }
      }
      .circleProgress {
        position: relative;
        width: 42px;
        height: 42px;
        .circleCommon {
          width: 50%;
          height: 100%;
          overflow: hidden;
          position: absolute;
          top: 0;
          z-index: 1;
          .progressPercent {
            width: 100%;
            height: 100%;
            background: none;
            border-width: 2px;
            border-style: solid;
            position: absolute;
            top: 0;
            border-color: #d14242;
          }
          &.Left {
            left: 0;
            display: none;
            .progressPercent {
              left: 100%;
              border-top-right-radius: 80px;
              border-bottom-right-radius: 80px;
              border-left: 0;
              -webkit-transform-origin: center left;
              transform-origin: center left;
            }
          }
          &.Right {
            right: 0;
            .progressPercent {
              left: -100%;
              border-top-left-radius: 80px;
              border-bottom-left-radius: 80px;
              border-right: 0;
              -webkit-transform-origin: center right;
              transform-origin: center right;
              animation: loading-1 1.8s linear forwards;
              transform: rotate(75deg);
            }
          }
        }
      }
    }
    h6 {
      color: #242c34;
      font-size: 16px;
      margin: 0;
    }
    span {
      color: #a5adba;
      font-size: 12px;
      display: block;
      margin-top: 0;
    }
  }
`;

class IndexPage extends React.PureComponent {
  state = {
    activeIndex: 0
  };

  setActiveIndex = activeIndex => this.setState({ activeIndex });

  render() {
    const { activeIndex } = this.state;
    return (
      <MainLayout
        showFooterRegister={false}
        noMenu
        noFooter
        hideSideCTA
        noHeader
        renderCustomHeader={() => (
          <HeaderSchedulePage hideMobileBottomNav noMenu />
        )}
      >
        <MySEO title="Bước 1: Khóa học online, trải nghiệm như offline" />
        <Main>
          <OnlineHeaderStep activeStep={0} />
          <Section selfish className="vh100">
            <Container>
              <BigSection>
                <Container>
                  <ImagePanel>
                    <div className="panelBody" style={{ flex: '50px 1 1' }}>
                      <div className="RespHeader">
                        <div className="cirle">
                          <div className="label">
                            <b>1</b>/5
                          </div>
                          <div className="circleProgress">
                            <div className="circleCommon Left ">
                              <span className="progressPercent" />
                            </div>
                            <div className="circleCommon Right ">
                              <span className="progressPercent" />
                            </div>
                          </div>
                        </div>
                        <h6>Set up</h6>
                        <span>Next: Bắt đầu học</span>
                      </div>
                      <BulletTitle>
                        {/* <div className="bullet">01</div> */}
                        <div>BƯỚC 01: SET UP</div>
                      </BulletTitle>
                      <p>
                        <b>Để đảm bảo không có vấn đề kĩ thuật nào phát sinh</b>{' '}
                        làm gián đoạn trải nghiệm học tập của học viên, trước
                        khi học DOL sẽ có 1 buổi demo hướng dẫn set up các ứng
                        dụng dùng trong việc học online, gồm: <br />
                        - Cách cài đặt Zoom trước buổi học và các lưu ý.
                        <br />- Cách sử dụng Zoom hiệu quả trong buổi học.
                      </p>
                      <div className="TutorialVideo">
                        <h6>Video hướng dẫn</h6>
                        <VideoContentWrp
                          className={cl({ active: activeIndex === 0 })}
                        >
                          <VideoThumbnail
                            poster={poster61}
                            maxWidth={864}
                            video={v1}
                          />
                        </VideoContentWrp>
                      </div>
                    </div>
                    <div
                      className="panelImage Step1"
                      style={{ flex: '50px 1 1' }}
                    >
                      <Image image={img15g1} maxWidth={500} />
                    </div>
                  </ImagePanel>
                </Container>
              </BigSection>
              <Panel>
                <div className="FixedFooter">
                  <div className="wrapper-footer">
                    <Link to="/online" className="btn btnBack">
                      Quay lại
                    </Link>
                    <Link to="/online/step2" className="btn btnNext">
                      Tiếp
                    </Link>
                  </div>
                </div>
              </Panel>
            </Container>
          </Section>
        </Main>
      </MainLayout>
    );
  }
}

IndexPage.propTypes = {
  pageContext: PropTypes.shape({
    meta: PropTypes.string.isRequired
  }).isRequired
};

export default IndexPage;
